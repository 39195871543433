import styled from 'styled-components'

export const ContainerBannerWeinmann = styled.div`
  margin: 12px 0;
  .carousel .control-dots .dot {
    border: 2px solid #047380 !important;
    margin: 0 3px;
  }

  @media (min-width: 768px) {
    margin: 15px 0 12px 0;
  }

  @media (min-width: 1024px) {
    margin: 0 0 12px 0;
  }

`
export const ContainerCardsWeinmann = styled.div`
  margin-top: 140px;
  @media (min-width: 768px) {
    margin-top: 150px;
  }
  @media (min-width: 1023px) {
    margin-top: 20px;
  }

  > div {
    overflow: unset;
  }
`
export const ClientHomeMobile = styled.div`
  .MuiGrid-item {
    width: 100%;
  }

  .grid-desktop-cards {
    @media (min-width: 768px) {
      display: flex;
      grid-gap: 30px;
    }
  }
`

export const HighlightsContainer = styled.div`
  img {
    width: 100%;
    margin-bottom: 12px;
  }

  a {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 24px 0 44px 0;

    @media (min-width: 768px) {
      width: 155px;
      margin: 40px 0 44px 0;
    }
  }

  @media (min-width: 768px) {
    width: 50%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`

export const NewsContainerMobile = styled.div`
  width: 100%;
  margin-bottom: 50px;

  .card {
    min-height: 250px;
  }

  /* .carousel .slide {
    background: transparent !important;
    height: 100%;

    img {
      height: 192px;
      width: 100%;
    } */

    .MuiCard-root {
      box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 0%), 0px 1px 1px 0px rgb(0 0 0 / 5%),
        0px 1px 10px 0px rgb(0 0 0 / 15%);
      border-radius: 10px;
    }

    .MuiCardContent-root {
      display: flex;
      flex-direction: column;
      text-align: start;
    }

    .MuiCardActions-root {
      display: flex;
      align-items: end;
      justify-content: flex-end;
      text-decoration: underline;
      padding-right: 16px;
    }
  }
  .carousel .control-dots li {
    border: 2px solid #047380 !important;
    margin: 0 3px;
  }

  .carousel .control-dots {
    margin: 10px 0 30px 0;
  }

  .see-more {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 8px;
    font-weight: normal;
  }

  #button-section-card-doctor {
    width: 100%;
    display: flex;
    justify-content: center;
    /* font-weight: normal; */
    border-width: 2px;
  }
`

export const ContentCard = styled.div`
  @media (min-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 85px;

    h3,
    p {
      margin: 0;
    }
  }
`
