import styled from 'styled-components'

export const Wrapper = styled.div`
  font-family: Signika;
  overflow: hidden;
  color: #373a3a;

  .carousel .slider-wrapper {
    @media (min-width: 425px) {
      min-height: 400px;
      .card{
        height:395px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }

    @media (min-width:768px) {
      margin-bottom: 20px;
      .card{
        min-height:355px;
        height: 355px;
      }
    }

    @media (min-width:1024px) {
      min-height: 405px;

      .card{
        height: 385px;
      }
    }
  }
  .carousel .slide {
    background-color: white !important;

    img {
      height: auto !important;
      width: 100%;
    }

    .MuiCardContent-root h3 {
      margin: 0 !important;
    }
  }

  .container-news {
    padding: 0;
  }

  .control-dots {
    margin-top: 15px !important;
    position: initial !important;
    li {
      width: 10px !important;
      height: 10px !important;
      box-shadow: none !important;
      border: 1px solid ${props => props.theme.color.primary};
      opacity: 1 !important;

      &.selected {
        background-color: ${props => props.theme.color.primary};
      }
    }
  }

  .MuiCardContent-root {
    max-height: 163px;
  }

`

export const CarouselItem = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 10px;

  max-height: 355px;
  margin-bottom: 10px;

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
`

export const MagazineName = styled.span`
  margin: 5px 0px;
  display: flex;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-decoration-line: underline;
  width: 100%;
  color: ${props => props.theme.color.primary};
`

export const MagazineDate = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #666666;
`
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;

  @media (min-width: 768px){
    width: 220px;
    margin: 0 auto;
  }
`
