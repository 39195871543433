import * as React from 'react'

import { CarouselItem, Wrapper, ButtonContainer } from './org.cards-section-client-news.style'

import {
  Container,
  useMediaQuery,
  useTheme,
  Card,
  CardContent,
  CardMedia,
  CardActions,
  CardActionArea
} from '@material-ui/core'
import Title from '../Title'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import { Button } from '@root/../atomic'
import { appPaths } from 'utils/path'

import { Link } from 'gatsby'
import { getDetailPageUrl } from 'utils/url'

export const CardsSectionClientNews = ({ news }: any) => {
  const theme = useTheme()

  const downOfSm = useMediaQuery(theme.breakpoints.down('xs'))

  return (
    <Wrapper>
      <Container className="container-news" maxWidth="md">
        <Title className="newLayout">Notícias</Title>
        <Carousel
          showArrows={false}
          showThumbs={false}
          showStatus={false}
          showIndicators={downOfSm ? true : false}
          swipeable={downOfSm ? true : false}
          centerMode
          centerSlidePercentage={downOfSm ? 80 : 33}
        >
          {news.slice(0, 3).map(item => (
            <CarouselItem>
              <>
                <Card className="card">
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      height="140"
                      image={item.node.metadata.thumbnail.imgix_url}
                    />
                    <CardContent>
                      <h3>{item.node.title}</h3>
                      {downOfSm ? (
                        <p>{item.node.metadata.descricaoSimples.substring(0, 80)}...</p>
                      ) : (
                        <p>{item.node.metadata.descricaoSimples}</p>
                      )}
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    <Link
                      className="see-more"
                      to={getDetailPageUrl(appPaths.news.path.clientUrl, item.node.slug)}
                    >
                      Leia mais
                    </Link>
                  </CardActions>
                </Card>
              </>
            </CarouselItem>
          ))}
        </Carousel>
        <ButtonContainer>
          <Button id={`button-section-card-doctor`} to="/noticias" kind={'secondary'}>
            Ver todas as notícias
          </Button>
        </ButtonContainer>
      </Container>
    </Wrapper>
  )
}
