import { NeutralBackground } from '@root/src/components/atm.background/background.styled'
import { SEO } from '@root/src/components/legacy/mol.seo/seo.component'
import { headerBreakpoint } from '@root/src/components/legacy/org.header/header.component.style'
import { CardKind } from '@root/src/components/mol.card/card.component'
import {
  clientQuickAccess,
  QuickAccess
} from '@root/src/components/mol.quick-access/quick-access.component'
import { Button } from '@root/../atomic'

import {
  CardData,
  CardsSection
} from '@root/src/components/org.cards-section/cards-section.component'
import {
  FeaturedCarousel,
  FeaturedCarouselProps
} from '@root/src/components/org.featured-carousel/featured-carousel.component'
import IndexLayout from '@root/src/components/org.layout/layout.component'
import { AccessTokenDataSource } from '@root/src/data/access-token.datasource'
import { Query, CosmicjsHomeCliente } from '@root/src/data/graphql/graphql-types'
import { PageProps } from '@root/src/utils/local-types'
import { MatchMedia } from 'atomic/legacy/obj.match-media'
import { BannerCarousel } from 'atomic/org.banner-carousel/banner-carousel.component'
import { graphql } from 'gatsby'
import React from 'react'
import { appPaths } from 'utils/path'
import { getDetailPageUrl } from 'utils/url'
import { FeaturedCarouselNew } from '@root/src/components/org.featured-carousel-new/featured-carousel-new.component'
import { BannerCarouselNew } from '@root/src/components/org.banner-carousel-new/banner-carousel.component-new'
import {
  ContainerBannerWeinmann,
  ClientHomeMobile,
  HighlightsContainer,
  NewsContainerMobile,
  ContentCard
} from './client-home-styled.jsx'

import { CardsSectionClientNews } from '@root/src/components/org.cards-section-client-news/org.cards-section-client-news'
import { Container, Grid } from '@material-ui/core'
import Title from '@root/src/components/Title'

let ClientLastSectionComponent
if (process.env.GATSBY_MARCA === 'FLEURY') {
  ClientLastSectionComponent = require('fleury/modules/home/org.home-section/client-home-differentials-section.component')
    .default
} else {
  ClientLastSectionComponent = () => null
}
export interface ClientHomeDetailTemplateProps {
  cosmicjsHomeCliente?: CosmicjsHomeCliente
}

class Home extends React.Component<PageProps<ClientHomeDetailTemplateProps, Query>> {
  render() {
    const accessTokenDataSource: AccessTokenDataSource = new AccessTokenDataSource(
      process.env.GATSBY_FLEURY_BASE_URL,
      process.env.GATSBY_FLEURY_CLIENT_ID_SENSEDIA,
      process.env.GATSBY_FLEURY_BASE64_AUTH
    )

    accessTokenDataSource.getAccessToken()

    const data = this.props.data
    const banners = data.cosmicjsHomeCliente.metadata.carrosselBanners.map(item => ({
      image: item.imagem.imgix_url,
      link: item.link
    }))

    const bannersMain = data.cosmicjsHomeCliente.metadata.carrosselBanners.map(item => ({
      image: item.imagem.imgix_url,
      image_mobile: item.imagem_mobile.imgix_url,
      link: item.link
    }))

    const allCosmicjsNoticias = data.allCosmicjsNoticias.edges

    const metadata = data.cosmicjsHomeCliente.metadata
    const newsCards = data.allCosmicjsNoticias.edges.map(card => ({
      imgSrc: card.node.metadata.thumbnail.imgix_url,
      imgAlt: metadata.seo.imagemAlternativa,
      title: card.node.title,
      body: card.node.metadata.descricaoSimples,
      linkText: 'Leia mais',
      linkTo: getDetailPageUrl(appPaths.news.path.clientUrl, card.node.slug),
      kind: CardKind.image
    })) as [CardData, CardData, CardData]

    const carouselProps: FeaturedCarouselProps = {
      items: metadata.destaquesCarrossel.map(item => ({
        area: item.assunto,
        title: item.titulo,
        description: item.descricao,
        link: item.link,
        image: {
          url: item.imagem.imgix_url,
          alt: item.imagemAlternativa
        }
      }))
    }

    return (
      <IndexLayout location={this.props.location}>
        <SEO
          socialMedia={{
            canonicalUrl: `${this.props.data.site.siteMetadata.siteUrl}${this.props.location.pathname}${this.props.location.search}`,
            title: data.cosmicjsHomeCliente.metadata.seo.titulo,
            image: data.cosmicjsHomeCliente.metadata.seo.imagem.url,
            imageAlt: data.cosmicjsHomeCliente.metadata.seo.imagemAlternativa,
            description: data.cosmicjsHomeCliente.metadata.seo.descricao
          }}
        />

        {process.env.GATSBY_COSMIC_BUCKET.toLowerCase() === 'weinmann' ? (
          <>
            <ContainerBannerWeinmann>
              <BannerCarouselNew banners={bannersMain} />
            </ContainerBannerWeinmann>

            <Container maxWidth="md">
              <ClientHomeMobile>
                <Grid container>
                  <Grid className="grid-desktop-cards" item>
                    {metadata.cards_destaques.map(item => (
                      <HighlightsContainer>
                        <img src={item.imagem.url}></img>
                        <Title className="newLayout">{item.titulo}</Title>
                        <ContentCard>
                          <h3>{item.subtitulo}</h3>
                          <p>{item.descricao}</p>
                        </ContentCard>

                        <Button id={`button-section-card-doctor`} to={item.link} kind={'primary'}>
                          Saiba mais
                        </Button>
                      </HighlightsContainer>
                    ))}
                  </Grid>
                  <Grid item>
                    <NewsContainerMobile>
                      <CardsSectionClientNews news={allCosmicjsNoticias} />
                    </NewsContainerMobile>
                  </Grid>
                </Grid>
              </ClientHomeMobile>
            </Container>
            <FeaturedCarouselNew {...carouselProps} />
          </>
        ) : (
          <>
            <QuickAccess isDoctor={false} options={clientQuickAccess} />

            <MatchMedia defaultMinWidth={headerBreakpoint}>
              {md =>
                !md && (
                  <NeutralBackground>
                    <BannerCarousel banners={banners} />
                  </NeutralBackground>
                )
              }
            </MatchMedia>
            <CardsSection
              metadata={{
                sectionTitle: metadata.noticiasAssunto,
                displayText: metadata.noticiasTitulo,
                buttonText: 'Ver todas notícias',
                buttonLinkto: appPaths.news.path.clientUrl
              }}
              cardsData={[newsCards[0], newsCards[1], newsCards[2]]}
              id="client-home-news"
            />
            <FeaturedCarousel {...carouselProps} />
            <ClientLastSectionComponent {...this.props.pageContext} />
          </>
        )}
      </IndexLayout>
    )
  }
}

export default Home

export const query = graphql`
  query HomeDetail {
    site {
      siteMetadata {
        siteUrl
      }
    }

    cosmicjsHomeCliente(slug: { eq: "home-cliente" }) {
      id
      title
      content
      metadata {
        carrosselBanners {
          link
          imagem {
            url
            imgix_url
          }
          imagem_mobile {
            url
            imgix_url
          }
        }
        noticiasAssunto
        noticiasTitulo
        destaquesCarrossel {
          assunto
          titulo
          descricao
          link
          imagemAlternativa
          imagem {
            url
            imgix_url
          }
        }

        seo {
          titulo
          imagemAlternativa
          imagem {
            url
            imgix_url
          }
          descricao
        }

        cards_destaques {
          titulo
          subtitulo
          descricao
          link
          imagem {
            url
            imgix_url
          }
        }
      }
    }

    allCosmicjsNoticias(sort: { order: DESC, fields: [metadata___noticiaData] }, limit: 3) {
      edges {
        node {
          title
          slug
          type_slug
          metadata {
            descricaoSimples
            thumbnail {
              url
              imgix_url
            }
          }
        }
      }
    }
  }
`
